import { useCallback } from 'react';
import { useField } from 'formik';

import CheckboxInput from 'components/forms/CheckboxInput';
import NumberInput from 'components/forms/NumberInput';
import RadioGroup from 'components/forms/RadioGroup';
import RolesSelect from 'components/forms/RolesSelect';
import SelectInput, { TYPES } from 'components/forms/SelectInput';
import TextField from 'components/forms/TextField';
import TextInput from 'components/forms/TextInput';
import ToggleInput from 'components/forms/ToggleInput';

import { omit, pick } from 'util/objectMethods';

const ComponentMap = {
  NumberInput: {
    component: NumberInput,
    allowedProps: ['isFormik'],
  },
  CheckboxInput: {
    component: CheckboxInput,
  },
  RadioGroup: {
    component: RadioGroup,
  },
  TextInput: {
    component: TextInput,
  },
  SelectInput: {
    component: SelectInput,
    allowedProps: ['formikFieldHelpers', 'placeholder'],
  },
  TextField: {
    component: TextField,
    allowedProps: ['formikFieldHelpers'],
  },
  ToggleInput: {
    component: ToggleInput,
  },
  RolesSelect: {
    component: RolesSelect,
  },
};

const FormikInput = ({ component, onChange, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [field, _meta, formikFieldHelpers] = useField(props.name);
  const { onChange: fieldOnChange, onBlur, ...fieldProps } = field;
  const handleChange = useCallback(
    (_, e) => {
      fieldOnChange(e);
      return onChange && onChange(e);
    },
    [fieldOnChange, onChange]
  );
  const handleBlur = useCallback(
    (_, e) => {
      if (e) {
        onBlur(e);

        if (props.onBlur) {
          props.onBlur(e);
        }
      }
    },
    [onBlur, props]
  );

  const eventHandlers = { onChange: handleChange, onBlur: handleBlur };

  if (component === 'TextInput') {
    props.valueOnChange = true;
  }

  if (component === 'SelectInput') {
    eventHandlers.onBlur = onBlur;

    if (!TYPES.includes(props.type)) {
      props = omit(props, ['type']);
    }
  }

  if (component === 'CheckboxInput') {
    props = omit(props, ['type']);
  }

  const { component: Component, allowedProps = [] } = ComponentMap[component];
  const optionalProps = pick(
    {
      isFormik: true,
      formikFieldHelpers,
    },
    allowedProps
  );

  return (
    <Component
      title={props.name}
      {...optionalProps}
      {...props}
      {...fieldProps}
      {...eventHandlers}
    />
  );
};

export default FormikInput;
